import { Injectable } from '@angular/core';
import { BaseService } from '../classes/base.service';
import { HttpService } from './http.service';
import { CacheService } from './cache.service';
@Injectable({
  providedIn: 'root'
})
export class RefCodeService extends BaseService {

  constructor(private httpservice: HttpService, private cacheService: CacheService) {
    super();
  }

  lookupmap: any = {};

  getMappedKey(key: string) {
    return this.lookupmap[key] ? this.lookupmap[key] : key;
  }

  getRefCodes(types: string[]): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let refcodes: any = {};
      let missingTypes: string[] = [];

      // Check cache for each requested type with prefixed key
      types.forEach(type => {
        const prefixedKey = `RefCode_${this.getMappedKey(type)}`;
        const cachedData = this.cacheService.get(prefixedKey);
        if (cachedData) {
          // Remove prefix when adding to refcodes
          refcodes[type] = cachedData;
        } else {
          missingTypes.push(type);
        }
      });

      // If all types are cached, resolve immediately
      if (missingTypes.length === 0) {
        resolve(refcodes);
        return;
      }

      // Prepare lookup data for only missing types
      const lookupdata = { "keys": missingTypes.map(x => this.getMappedKey(x)) };

      // Fetch from server only for missing types
      this.httpservice.Post<any>(false, false, '/lookup', lookupdata).then(
        (resp) => {
          missingTypes.forEach(type => {
            const mappedKey = this.getMappedKey(type);
            refcodes[type] = resp.data[mappedKey] || [];
            // Save data in cache with prefixed key
            const prefixedKey = `RefCode_${mappedKey}`;
            this.cacheService.set(prefixedKey, refcodes[type]);
          });
          resolve(refcodes);
        }
      ).catch(error => {
        reject(error);
      });
    });
  }
}
